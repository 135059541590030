import { Subject } from 'rxjs';
import TVPlatform from '../tv-platform';
import { ErrorType } from '../tv-platform/types';
import { getBannerPreferenceHeaders, getOneTrustTokenHeaders, getSaveApiHeaders } from './headers';
import { Storage } from '@lightningjs/sdk';
import { STORAGE_KEYS, USER_OPT_OUT_PREFERENCE } from '../../constants';
import AppConfigFactorySingleton from '../../config/AppConfigFactory';
import { createHook } from '../Hook';
import { CMPGroupIds } from '../../pages/Settings/CMPPreferenceCenter/types';
import LaunchDarklySingleton from '../launchDarkly/LaunchDarkly';
import LaunchDarklyFeatureFlags from '../launchDarkly/LaunchDarklyFeatureFlags';
var CMPEventType;
(function (CMPEventType) {
    CMPEventType["BANNER"] = "banner_ui";
    CMPEventType["PREFERENCE"] = "preference";
    CMPEventType["SAVE"] = "save";
})(CMPEventType || (CMPEventType = {}));
export var CMPInteractionType;
(function (CMPInteractionType) {
    CMPInteractionType["BANNER_ALLOW_ALL"] = "BANNER_ALLOW_ALL";
    CMPInteractionType["BANNER_REJECT_ALL"] = "BANNER_REJECT_ALL";
    CMPInteractionType["BANNER_CLOSE"] = "BANNER_CLOSE";
    CMPInteractionType["BANNER_CONTINUE_WITHOUT_ACCEPTING"] = "BANNER_CONTINUE_WITHOUT_ACCEPTING";
    CMPInteractionType["PREFERENCE_CENTER_ALLOW_ALL"] = "PREFERENCE_CENTER_ALLOW_ALL";
    CMPInteractionType["PREFERENCE_CENTER_REJECT_ALL"] = "PREFERENCE_CENTER_REJECT_ALL";
    CMPInteractionType["PREFERENCE_CENTER_CONFIRM"] = "PREFERENCE_CENTER_CONFIRM";
    CMPInteractionType["PREFERENCE_CENTER_CLOSE"] = "PREFERENCE_CENTER_CLOSE";
    CMPInteractionType["VENDOR_LIST_ALLOW_ALL"] = "VENDOR_LIST_ALLOW_ALL";
    CMPInteractionType["VENDOR_LIST_REJECT_ALL"] = "VENDOR_LIST_REJECT_ALL";
    CMPInteractionType["VENDOR_LIST_CONFIRM"] = "VENDOR_LIST_CONFIRM";
    CMPInteractionType["SDK_LIST_ALLOW_ALL"] = "SDK_LIST_ALLOW_ALL";
    CMPInteractionType["SDK_LIST_REJECT_ALL"] = "SDK_LIST_REJECT_ALL";
    CMPInteractionType["SDK_LIST_CONFIRM"] = "SDK_LIST_CONFIRM";
    CMPInteractionType["SYNC_PROFILE"] = "SYNC_PROFILE";
})(CMPInteractionType || (CMPInteractionType = {}));
export const AllowSaleHook = createHook(false);
class ConsentManagementPlatform {
    constructor() {
        this._subject = new Subject();
    }
    get enabled() {
        return LaunchDarklySingleton.getFeatureFlag(LaunchDarklyFeatureFlags.cmpPreferenceCenter);
    }
    get storageKeys() {
        return Storage.get(STORAGE_KEYS.CMP_STORAGE_KEYS) || {};
    }
    set storageKeys(v) {
        if (!v || (typeof v === 'object' && !Object.keys(v).length))
            return;
        const consents = v['OT_GroupConsents'];
        if (consents && CMPGroupIds.ALLOW_SALE in consents) {
            AllowSaleHook().set(consents[CMPGroupIds.ALLOW_SALE]);
        }
        Storage.set(STORAGE_KEYS.CMP_STORAGE_KEYS, v);
        Storage.set(STORAGE_KEYS.CMP_GPP_STRING, v.IABGPP_HDR_GppString);
    }
    get consentString() {
        return Storage.get(STORAGE_KEYS.CMP_CONSENT_STRING) || '';
    }
    set consentString(v) {
        if (v) {
            Storage.set(STORAGE_KEYS.CMP_CONSENT_STRING, v);
        }
    }
    get preferenceData() {
        return this._preferenceData;
    }
    set preferenceData(value) {
        this._preferenceData = value;
    }
    async sync() {
        if (!this.enabled)
            return;
        try {
            await Promise.all([this.fetchBannerUi(), this.fetchPreferenceCenter()]);
        }
        catch (e) {
            TVPlatform.reportError({
                type: ErrorType.NETWORK,
                description: 'Failure loading OneTrust API',
                payload: e,
            });
            // fail silently
        }
    }
    subscribe(cb) {
        return this._subject.subscribe(cb);
    }
    async save(body) {
        if (!this.enabled)
            return;
        try {
            if (!AppConfigFactorySingleton.config.one_trust_id)
                throw new Error('CMP:: undefined app id');
            const response = await fetch(AppConfigFactorySingleton.config.one_trust_save_endpoint, {
                method: 'POST',
                headers: getSaveApiHeaders(),
                body: JSON.stringify(body),
            });
            const json = await response.json();
            this.storageKeys = json.storageKeys;
            this.consentString = json.otConsentString;
            return json;
        }
        catch (e) {
            this.storageKeys = {};
            this.consentString = '';
            TVPlatform.reportError({
                type: ErrorType.NETWORK,
                payload: e,
                description: 'Error saving CMP Save and Log consent data',
            });
            throw e;
        }
    }
    async fetchBannerUi() {
        if (!this.enabled)
            return;
        try {
            if (!AppConfigFactorySingleton.config.one_trust_id)
                throw new Error('CMP:: undefined app id');
            const response = await fetch(AppConfigFactorySingleton.config.one_trust_banner_endpoint, {
                headers: getBannerPreferenceHeaders(this.consentString),
            });
            const json = await response.json();
            this.storageKeys = json.storageKeys;
            this.consentString = json.otConsentString;
            this._subject.next(json);
            // TODO adjust types to actual response (type is modeled from docs data)
            return json;
        }
        catch (e) {
            this.storageKeys = {};
            this.consentString = '';
            TVPlatform.reportError({
                type: ErrorType.NETWORK,
                payload: e,
                description: 'Error requesting CMP BannerUI API',
            });
            throw e;
        }
    }
    async fetchPreferenceCenter() {
        try {
            if (!AppConfigFactorySingleton.config.one_trust_id)
                throw new Error('CMP:: undefined app id');
            const response = await fetch(AppConfigFactorySingleton.config.one_trust_preference_endpoint, {
                headers: getBannerPreferenceHeaders(this.consentString),
            });
            const json = await response.json();
            this.preferenceData = json;
            this.storageKeys = json.storageKeys;
            this.consentString = json.otConsentString;
            this._subject.next(json);
            // TODO adjust types to actual response (type is modeled from docs data)
            return json;
        }
        catch (e) {
            this.storageKeys = {};
            this.consentString = '';
            TVPlatform.reportError({
                type: ErrorType.NETWORK,
                payload: e,
                description: 'Error requesting CMP Preference Center API',
            });
            throw e;
        }
    }
    async ctvOptOutConfirm(override = {}) {
        var _a, _b;
        const { purposeTree: { purposes }, buttons: { savePreferencesButton }, } = ((_a = this.preferenceData) === null || _a === void 0 ? void 0 : _a.pcUIData) || {};
        const purposesMapped = (purposes || []).map((purpose) => ({
            groupId: purpose.groupId,
            status: purpose.consentToggleStatus === 1,
        }));
        const ctvOptOutConfirm = {
            interactionType: savePreferencesButton === null || savePreferencesButton === void 0 ? void 0 : savePreferencesButton.interactionType,
            consent: {
                purposesStatus: Object.entries({
                    ...(((_b = this.storageKeys) === null || _b === void 0 ? void 0 : _b.OT_GroupConsents) || {}),
                    ...purposesMapped,
                    ...override,
                })
                    .filter(([groupId]) => ['BG1192', 'OOF'].includes(groupId))
                    .map(([groupId, status]) => ({
                    groupId,
                    status: !!status,
                })),
            },
            userAgent: navigator.userAgent,
        };
        this.save(ctvOptOutConfirm);
    }
    async getOneTrustToken() {
        if (!this.enabled)
            return;
        try {
            const response = await fetch(AppConfigFactorySingleton.config.one_trust_token_endpoint, {
                headers: getOneTrustTokenHeaders(),
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const { onetrust_token } = await response.json();
            if (!onetrust_token) {
                throw new Error('No token received in response');
            }
            Storage.set(STORAGE_KEYS.ONE_TRUST_TOKEN, onetrust_token);
            await this.sync();
        }
        catch (e) {
            TVPlatform.reportError({
                type: ErrorType.NETWORK,
                description: 'Error fetching OneTrust token',
                payload: e,
            });
        }
    }
    getFWParams() {
        if (!this.enabled)
            return {
                usPrivacy: undefined,
                gpp: undefined,
                gppSid: undefined,
            };
        const { IABUSPrivacy_String, IABGPP_HDR_GppString, IABGPP_GppSID } = this.storageKeys;
        return {
            usPrivacy: IABUSPrivacy_String || undefined,
            gpp: IABGPP_HDR_GppString || undefined,
            gppSid: IABGPP_GppSID || undefined,
        };
    }
    deleteOneTrustToken() {
        Storage.remove(STORAGE_KEYS.ONE_TRUST_TOKEN);
    }
    getCmpOptOut() {
        var _a, _b;
        if (!this.enabled)
            return USER_OPT_OUT_PREFERENCE.ALLOW_SALE;
        return Number((_b = (_a = Storage.get(STORAGE_KEYS.CMP_STORAGE_KEYS)) === null || _a === void 0 ? void 0 : _a.OT_GroupConsents) === null || _b === void 0 ? void 0 : _b.BG1192)
            ? USER_OPT_OUT_PREFERENCE.ALLOW_SALE
            : USER_OPT_OUT_PREFERENCE.DISALLOW_SALE;
    }
}
export const ConsentManagement = new ConsentManagementPlatform();
