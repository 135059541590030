import { Lightning, Router, Storage } from '@lightningjs/sdk';
import SettingsButton from '../components/buttons/SettingsButton';
import SelectButton from '../components/buttons/SelectButton';
import { DebugControllerSingleton } from '../util/debug/DebugController';
import { COLORS, FONT_FACE, LegacyStoreKey, SCREEN_SIZE, STORAGE_KEYS, USER_OPT_OUT_PREFERENCE, VERTICAL_ALIGN, } from '../constants';
import { CoreVideoSdk, VideoPlatform } from '@sky-uk-ott/core-video-sdk-js';
import Preferences from '../lib/Preferences';
import { getCoreVideoSdkConfig, getPlayoutData, } from '../player/CoreVideoPlayer/getCoreVideoSdkConfig';
import TextButton from '../components/buttons/TextButton';
import LaunchDarklySingleton from '../lib/launchDarkly/LaunchDarkly';
import LaunchDarklyFeatureFlags from '../lib/launchDarkly/LaunchDarklyFeatureFlags';
import TVPlatform from '../lib/tv-platform';
import { loadDeviceFactory } from '@sky-uk-ott/client-lib-js-device';
var DebugLists;
(function (DebugLists) {
    DebugLists["Toggles"] = "Toggles";
    DebugLists["TempPassList"] = "TempPassList";
    DebugLists["Geo"] = "Geo";
    DebugLists["Env"] = "Env";
})(DebugLists || (DebugLists = {}));
const DebugList = [DebugLists.Toggles, DebugLists.TempPassList, DebugLists.Geo, DebugLists.Env];
// TempPass-OneApp-1min
const TEMP_PASS_IDS = ['1/2', '2/1', '10/30', '30/10', 'X'];
const getSettingsButtonProps = (selected) => ({
    h: 70,
    radius: 0,
    fontSize: 30,
    fontFace: FONT_FACE.light,
    focusFontColor: COLORS.dark,
    unfocusFontColor: COLORS.white,
    focusBackGroundColor: COLORS.lightGray3,
    unfocusBackgroundColor: COLORS.black3,
    padding: 0,
    icon: selected ? 'images/settings/selected.png' : 'images/settings/selection.png',
    focusSelectedIcon: 'focus_selected.png',
});
export default class Debug extends Lightning.Component {
    constructor() {
        super(...arguments);
        this._listIndex = 0;
    }
    static _template() {
        return {
            w: SCREEN_SIZE.width,
            h: SCREEN_SIZE.height,
            Toggles: {
                Qos: {
                    x: 100,
                    y: 170,
                    label: 'Enable QOS Panel',
                    type: SelectButton,
                    w: 350,
                    selected: DebugControllerSingleton.qosEnabled,
                    ancestor: '$selectChanged',
                    optionValue: 'qosEnabled',
                    ...getSettingsButtonProps(DebugControllerSingleton.qosEnabled),
                },
                HardCodedShelf: {
                    x: 100,
                    y: 250,
                    type: SelectButton,
                    w: 350,
                    label: 'Hard Coded Shelf',
                    selected: DebugControllerSingleton.hardCodedShelfEnabled,
                    ancestor: '$selectChanged',
                    optionValue: 'hardCodedShelfEnabled',
                    ...getSettingsButtonProps(DebugControllerSingleton.hardCodedShelfEnabled),
                },
                ToggleOptOut: {
                    x: 470,
                    y: 170,
                    type: SelectButton,
                    w: 350,
                    label: 'Disable Ad Tracking',
                    selected: DebugControllerSingleton.userOptOut,
                    ancestor: '$selectChanged',
                    optionValue: 'userOptOut',
                    ...getSettingsButtonProps(DebugControllerSingleton.userOptOut),
                },
                ToggleLiveGuide: {
                    x: 470,
                    y: 250,
                    type: SelectButton,
                    w: 350,
                    label: 'Force Live Guide V2',
                    selected: DebugControllerSingleton.liveGuideV2,
                    ancestor: '$selectChanged',
                    optionValue: 'liveGuideV2',
                    ...getSettingsButtonProps(DebugControllerSingleton.liveGuideV2),
                },
                TempPassEligible: {
                    x: 840,
                    y: 250,
                    type: SelectButton,
                    w: 350,
                    label: 'All SLE TP Eligible',
                    selected: DebugControllerSingleton.allProgramsTempPassEligible,
                    ancestor: '$selectChanged',
                    optionValue: 'allProgramsTempPassEligible',
                    ...getSettingsButtonProps(DebugControllerSingleton.allProgramsTempPassEligible),
                },
                XBoxDRMButton: {
                    x: 1210,
                    y: 170,
                    w: 350,
                    h: 70,
                    type: TextButton,
                    label: 'Show XBox DRM ID',
                    radius: 0,
                    fontSize: 28,
                    strokeWidth: 0,
                    fontFace: FONT_FACE.regular,
                    focusFontColor: COLORS.green,
                    unfocusFontColor: COLORS.white,
                    focusBackGroundColor: COLORS.white,
                    unfocusBackgroundColor: COLORS.green,
                    autoWidth: false,
                    padding: 0,
                },
            },
            TempPassReset: {
                x: 100,
                y: 330,
                flex: { direction: 'row' },
                TempPassResetTitle: {
                    h: 70,
                    flexItem: { marginTop: 10 },
                    text: {
                        lineHeight: 70,
                        verticalAlign: VERTICAL_ALIGN.middle,
                        fontSize: 30,
                        fontFace: 'Bold',
                        text: 'Temp Pass Reset:',
                    },
                },
                TempPassList: {
                    flexItem: { grow: 1, marginLeft: 10 },
                    flex: { direction: 'row' },
                },
                TempPassResetResponseMessage: {
                    h: 70,
                    flexItem: { marginTop: 10, marginLeft: 6 },
                    text: {
                        fontSize: 24,
                        lineHeight: 70,
                        verticalAlign: VERTICAL_ALIGN.middle,
                    },
                },
            },
            GeoAndEnvContainer: {
                GeoTitle: {
                    x: 100,
                    y: 420,
                    text: {
                        fontSize: 35,
                        fontFace: 'Bold',
                        text: 'Geolocation',
                    },
                },
                Geo: {
                    x: 100,
                    y: 470,
                    flex: { direction: 'column' },
                },
                EnvTitle: {
                    x: 1030,
                    y: 420,
                    text: {
                        fontSize: 35,
                        fontFace: 'Bold',
                        text: 'Environment',
                    },
                },
                Env: {
                    x: 1030,
                    y: 470,
                    flex: { direction: 'column' },
                },
            },
            CVSDKVersion: {
                x: 1800,
                y: 1010,
                mountX: 1,
                text: {
                    fontFace: FONT_FACE.light,
                    fontSize: 28,
                    textColor: COLORS.lightGray,
                    text: `CVSDK version ${CoreVideoSdk.version}`,
                },
            },
            LocalStorageViewer: {
                x: 1460,
                y: 875,
                mountX: 1,
                text: {
                    fontFace: FONT_FACE.light,
                    fontSize: 20,
                    textColor: COLORS.white,
                },
            },
            XBoxDeviceIdText: {
                x: 1800,
                y: 975,
                mountX: 1,
                text: {
                    fontFace: FONT_FACE.light,
                    fontSize: 28,
                    textColor: COLORS.white,
                },
                visible: false,
            },
        };
    }
    _init() {
        this._createGeoList();
        this._createEnvList();
        this._createTempPassList();
        //Toggles
        this._patchToggles();
        this._patchLocalStorageViewer();
        this._index = 0;
        this._tempPassOptionsIndex = 0;
        this._xboxDRMShown = false;
    }
    _createGeoList() {
        const geoOptions = [
            'Default',
            'Disabled',
            'Bay Area',
            'Chicago',
            'Washington',
            'Boston',
            'Philadelphia',
        ];
        this.tag('GeoAndEnvContainer.Geo').patch({
            children: geoOptions.map((item) => {
                const selected = DebugControllerSingleton.geo === item;
                return this._createSelectButton(item, selected);
            }),
        });
    }
    _createEnvList() {
        const envOptions = ['Default', 'Production', 'Stage', 'Acc', 'Dev'];
        this.tag('GeoAndEnvContainer.Env').patch({
            children: envOptions.map((item) => {
                const selected = DebugControllerSingleton.env === item.toLowerCase();
                return this._createSelectButton(item, selected);
            }),
        });
    }
    _createTempPassList() {
        this.tag('TempPassReset.TempPassList').patch({
            children: TEMP_PASS_IDS.map((item) => {
                return this._createSelectButton(item, false, 200);
            }),
        });
    }
    _patchToggles() {
        this.patch({
            Toggles: {
                Qos: {
                    selected: DebugControllerSingleton.qosEnabled,
                },
                HardCodedShelf: {
                    selected: DebugControllerSingleton.hardCodedShelfEnabled,
                },
                ToggleOptOut: {
                    selected: DebugControllerSingleton.userOptOut,
                },
                ToggleLiveGuide: {
                    selected: DebugControllerSingleton.liveGuideV2,
                },
                TempPassEligible: {
                    selected: DebugControllerSingleton.allProgramsTempPassEligible,
                },
            },
        });
    }
    _patchLocalStorageViewer() {
        this.patch({
            LocalStorageViewer: {
                text: {
                    text: this.localStorageLabel,
                },
            },
        });
    }
    async _patchXBoxDeviceId() {
        let deviceMsg = 'Error: available on device only';
        this.patch({
            XBoxDRMButton: {
                visible: false,
            },
            XBoxDeviceIdText: {
                text: {
                    text: 'Loading...',
                },
                visible: true,
            },
        });
        this._xboxDRMShown = true;
        this._index--;
        try {
            if (!CoreVideoSdk.isInitialised) {
                const deviceFactory = await loadDeviceFactory(TVPlatform.deviceSdkConfig);
                const device = await deviceFactory.initialise();
                await CoreVideoSdk.initialise(getCoreVideoSdkConfig(), device, {
                    type: VideoPlatform.OVP,
                    getVodPlayoutData: () => getPlayoutData(true),
                    getPreviewPlayoutData: () => getPlayoutData(true),
                    getLivePlayoutData: () => getPlayoutData(false),
                    getEventPlayoutData: () => getPlayoutData(false),
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    sendHeartbeat: async () => { },
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    stopHeartbeat: async () => { },
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    updateBookmark: async () => { },
                    validateLicenseResponse: () => true,
                    decorateLicenseRequest: (licenseRequest) => licenseRequest,
                });
            }
            const deviceId = CoreVideoSdk.deviceInfo.uniqueDeviceId;
            deviceMsg =
                deviceId.indexOf('-') === -1
                    ? 'Device ID available on device only'
                    : `XBox DeviceID (DRM): ${deviceId}`;
        }
        catch (err) {
            deviceMsg = err;
        }
        this.patch({
            XBoxDeviceIdText: {
                text: {
                    text: deviceMsg,
                },
            },
        });
    }
    _handleQosChange(value) {
        DebugControllerSingleton.qosEnabled = value;
    }
    _handleHcsChange(value) {
        DebugControllerSingleton.hardCodedShelfEnabled = value;
    }
    _handleTempPassReset(tempPassKey) {
        if (tempPassKey === 'X') {
            this.tag('TempPassReset.TempPassResetResponseMessage').text.text =
                'Cleared Debug TempPass Name';
            DebugControllerSingleton.tempPassName = null;
            return;
        }
        this.tag('TempPassReset.TempPassResetResponseMessage').text.text = 'Resetting...';
        const keys = tempPassKey.split('/').map((k) => `TempPass-OneApp-${k}min`);
        DebugControllerSingleton.tempPassName = keys.join('/');
        Promise.all(keys.map((k) => {
            var _a;
            return (_a = DebugControllerSingleton._resetTempPass(k)) === null || _a === void 0 ? void 0 : _a.then((res) => {
                this.tag('TempPassReset.TempPassResetResponseMessage').text.text =
                    'Reset completed for "' + k + '".';
            }).catch((err) => {
                this.tag('TempPassReset.TempPassResetResponseMessage').text.text =
                    'Error in resetting "' + k + '".';
                DebugControllerSingleton.tempPassName = null;
            });
        }));
    }
    $selectChanged(v) {
        const focusedItem = this._getFocused();
        const selected = !focusedItem.selected;
        focusedItem.selected = selected;
        DebugControllerSingleton[v] = selected;
    }
    get focusItem() {
        return this._getChildItem();
    }
    get localStorageLabel() {
        var _a;
        // Legacy
        const legacyDeviceId = DebugControllerSingleton._getGenericValue(LegacyStoreKey.deviceId);
        const legacyAccessToken = DebugControllerSingleton._getGenericValue(LegacyStoreKey.accessToken);
        const truncatedLegacyAccessToken = !legacyAccessToken
            ? ''
            : `${legacyAccessToken.substring(0, 10)}...${legacyAccessToken.substring(legacyAccessToken.length - 10, legacyAccessToken.length)}`;
        const legacyLanguage = DebugControllerSingleton._getGenericValue(LegacyStoreKey.language);
        // Local lightning
        const deviceId = Preferences.get(Preferences.DEVICE_ID);
        const accessToken = Preferences.get(Preferences.ACCESS_TOKEN);
        const truncatedAccessToken = !accessToken
            ? ''
            : `${accessToken.substring(0, 10)}...${accessToken.substring(accessToken.length - 10, accessToken.length)}`;
        const language = Storage.get(STORAGE_KEYS.APP_LANGUAGE);
        const liveGuideLDFlag = (_a = LaunchDarklySingleton.getFeatureFlag(LaunchDarklyFeatureFlags.liveGuide)) === null || _a === void 0 ? void 0 : _a.enableV2;
        const advertiserId = TVPlatform.getAdvertiserId();
        const userOptOut = TVPlatform.getUserOptOut() === USER_OPT_OUT_PREFERENCE.DISALLOW_SALE
            ? 'Opted Out'
            : 'Opted In';
        return `LD LiveGuidev2 Flag: ${liveGuideLDFlag}, \ndeviceId: ${deviceId}, \nadvertiserId: ${advertiserId}, \nUser Opt Out: ${userOptOut}, \nAccess Token: ${truncatedAccessToken}, \nappLanguage: ${language} \nlegacydeviceId: ${legacyDeviceId}, \nlegacy Access Token: ${truncatedLegacyAccessToken}, \nlegacy appLanguage: ${legacyLanguage}`;
    }
    _createSelectButton(label, selected, width = 740) {
        return {
            flexItem: { marginTop: 10, marginRight: 10 },
            w: width,
            type: SettingsButton,
            label: label,
            ...getSettingsButtonProps(selected),
        };
    }
    _getChildItem() {
        return this.tag(DebugList[this._listIndex]).children[this._index];
    }
    _getFocused() {
        return this.focusItem || this;
    }
    _handleUp() {
        if (this._listIndex <= 0)
            return Router.focusWidget('Menu');
        this._listIndex--;
        this._index = 0;
    }
    _handleDown() {
        if (this._listIndex < DebugList.length - 1) {
            this._listIndex++;
            this._index = 0;
        }
    }
    _handleLeft() {
        if (this._index > 0) {
            this._index -= 1;
        }
        else if (this._listIndex > 0) {
            this._listIndex -= 1;
            this._index = this._getLengthFromCurrentList() - 1;
        }
    }
    _handleRight() {
        if (this._index < this._getLengthFromCurrentList() - 1) {
            this._index += 1;
        }
        else if (this._listIndex < DebugList.length - 1) {
            this._listIndex += 1;
            this._index = 0;
        }
    }
    _handleEnter() {
        if (this.focusItem.parent.ref === 'TempPassList') {
            this._handleTempPassReset(this.focusItem._label);
        }
        else if (this.focusItem.ref === 'XBoxDRMButton') {
            this._patchXBoxDeviceId();
        }
        else {
            switch (DebugList[this._listIndex]) {
                case 'Geo':
                    this._geoSelected();
                    break;
                case 'Env':
                    this._envSelected();
                    break;
                default:
                    break;
            }
        }
    }
    _geoSelected() {
        // Needs to be -3 to account for the rows above
        DebugControllerSingleton.geo = this.tag('Geo').children[this._index]._label;
        this._createGeoList();
    }
    _envSelected() {
        // Needs to be -3 to account for the rows above
        DebugControllerSingleton.env = this.tag('Env').children[this._index]._label;
        this._createEnvList();
    }
    _getLengthFromCurrentList() {
        var _a;
        return this.tag((_a = DebugList[this._listIndex]) !== null && _a !== void 0 ? _a : '').children.length;
    }
}
